import { PrivacyStatus, RoleId } from '@wix/members-domain-ts';

import { origin } from '../../../constants/bi';
import { BIEvent, ThunkExtra, ThunkWithArgs } from '../../../types';
import { RootState } from '../../root-reducer';
import {
  Notification,
  openNotification,
  openModalWithCallback,
} from '../../../services/modal';
import { emitBIEvent } from '../../../services/bi-event';
import { getSetUsersAction } from '../../actions';
import { scheduleViewedMemberSync } from '../common';

interface OpenCommunityModalOptions {
  state: RootState;
  extra: ThunkExtra;
  roleId: RoleId;
  privacyStatus: PrivacyStatus;
  onConfirm: () => Promise<void>;
}

interface GetMembersOptions {
  extra: ThunkExtra;
  viewedUid: string;
  currentUid: string;
}

const getNotification = (privacyStatus: PrivacyStatus) =>
  privacyStatus === PrivacyStatus.Public
    ? Notification.JoinedCommunity
    : Notification.LeftCommunity;

const getShowModalBIEvent = (privacyStatus: PrivacyStatus) =>
  privacyStatus === PrivacyStatus.Public
    ? BIEvent.ShowJoinCommunityModal
    : BIEvent.ShowLeaveCommunityModal;

const getModalConfirmBIEvent = (privacyStatus: PrivacyStatus) =>
  privacyStatus === PrivacyStatus.Public
    ? BIEvent.JoinedCommunity
    : BIEvent.LeftCommunity;

const openCommunityModal = ({
  state,
  extra,
  roleId,
  privacyStatus,
  onConfirm,
}: OpenCommunityModalOptions) => {
  const { compId, platformAPIs, wixCodeApi } = extra;
  const payload = { originalAppComponent: origin };

  emitBIEvent({ state, extra, biEvent: getShowModalBIEvent(privacyStatus) });
  openModalWithCallback({
    compId,
    modalType: roleId,
    payload,
    platformAPIs,
    wixCodeApi,
    onConfirm,
  });
};

const openCommunityNotification = (
  privacyStatus: PrivacyStatus,
  { compId, flowAPI, wixCodeApi }: ThunkExtra,
) => {
  openNotification({
    compId,
    wixCodeApi,
    notification: getNotification(privacyStatus),
    isMobile: flowAPI.environment.isMobile,
  });
};

const getMembers = async ({
  extra,
  viewedUid,
  currentUid,
}: GetMembersOptions) => {
  const { membersService } = extra;

  if (viewedUid === currentUid) {
    const updatedMember = await membersService.getMember(viewedUid);
    return {
      viewed: updatedMember,
      current: updatedMember,
    };
  }

  const getViewedMember = membersService.getMember(viewedUid);
  const getCurrentMember = membersService.getMember(currentUid);
  const [viewed, current] = await Promise.all([
    getViewedMember,
    getCurrentMember,
  ]);

  return { viewed, current };
};

const communityRoleActionFactory = (
  privacyStatus: PrivacyStatus,
): ThunkWithArgs<RoleId> => {
  return (roleId) => (dispatch, getState, extra) => {
    const state = getState();
    const { viewed, current } = state.users;
    const { membersService } = extra;

    const onConfirm = async () => {
      await membersService.setCurrentMemberPrivacyStatus(privacyStatus);

      const currentUid = current ? current.uid : '';
      const members = await getMembers({
        extra,
        viewedUid: viewed.uid,
        currentUid,
      });

      scheduleViewedMemberSync(extra);
      dispatch(getSetUsersAction(members));
      emitBIEvent({
        state,
        extra,
        biEvent: getModalConfirmBIEvent(privacyStatus),
      });
      openCommunityNotification(privacyStatus, extra);
    };

    openCommunityModal({ state, extra, roleId, privacyStatus, onConfirm });
  };
};

export const joinCommunity = communityRoleActionFactory(PrivacyStatus.Public);

export const leaveCommunity = communityRoleActionFactory(PrivacyStatus.Private);
