import {
  BIEvent,
  CommonBIEvent,
  MetaData,
  Nullable,
  ThunkExtra,
} from '../types';
import { PartialUpdatableFields } from './members-service';
import { RootState } from '../store/root-reducer';
import { BIEventPayload } from './bi-logger';

interface EmitBIEventOptions {
  biEvent: BIEvent;
  state: RootState;
  extra: ThunkExtra;
}

interface EmitBIEventWithPayloadOptions<T extends BIEvent> {
  biEvent: T;
  payload: Omit<BIEventPayload<T>, keyof CommonBIEvent>;
  state: RootState;
  extra: Pick<ThunkExtra, 'flowAPI' | 'metaData' | 'biLogger'>;
}

const getCommonBIEventProps = (
  { controllerConfig }: ThunkExtra['flowAPI'],
  { site, users }: RootState,
  metaData: Nullable<MetaData>,
): CommonBIEvent => ({
  app_id: controllerConfig.appParams.appDefinitionId,
  biToken: metaData?.biToken ?? '',
  instance_id: controllerConfig.appParams.instanceId,
  is_social: toBIBoolean(site.isSocial),
  role: users.viewed.roles[0] ?? '',
  _: new Date().getTime(),
});

const isUndefined = <T>(value: T | undefined) => value === undefined;

const toBIBoolean = <T>(value: T) => (value ? 1 : 0);

export const emitBIEvent = ({
  biEvent,
  state,
  extra: { flowAPI, metaData, biLogger },
}: EmitBIEventOptions) => {
  const props = getCommonBIEventProps(flowAPI, state, metaData);
  return biLogger?.log(biEvent, props);
};

export const emitBIEventWithPayload = async <T extends BIEvent>({
  biEvent,
  payload,
  state,
  extra: { flowAPI, biLogger, metaData },
}: EmitBIEventWithPayloadOptions<T>) =>
  biLogger?.log(biEvent, {
    ...getCommonBIEventProps(flowAPI, state, metaData),
    ...payload,
  } as BIEventPayload<T>);

export const emitProfileEditedBIEvent = (
  state: RootState,
  updatedFields: PartialUpdatableFields,
  extra: ThunkExtra,
) =>
  emitBIEventWithPayload({
    state,
    extra,
    biEvent: BIEvent.ProfileEdited,
    payload: {
      name_changed: toBIBoolean(updatedFields.name),
      photo_changed: toBIBoolean(updatedFields.picture),
      cover_photo_change: toBIBoolean(updatedFields.cover),
      titleChanged: toBIBoolean(!isUndefined(updatedFields.title)),
    },
  });

const getAuthorEditedBIEventPayload = (
  { users: { viewed } }: RootState,
  updatedFields: PartialUpdatableFields,
) => {
  const newAuthorTitle = updatedFields.title ?? viewed.title;

  return {
    author_id: viewed.uid,
    author_role: viewed.roles[0] ?? '',
    cover_changed: !!updatedFields.cover,
    has_cover: !!viewed.cover,
    has_picture: !!viewed.picture,
    new_author_name: updatedFields.name ?? viewed.name,
    ...(newAuthorTitle && { new_author_title: newAuthorTitle }),
    origin: 'members area profile',
    was_name_changed: !!updatedFields.name,
    was_picture_changed: !!updatedFields.picture,
  };
};

export const emitAuthorEditedBIEvent = (
  state: RootState,
  updatedFields: PartialUpdatableFields,
  { flowAPI, blogBILogger, metaData }: ThunkExtra,
) => {
  const payload = {
    ...getCommonBIEventProps(flowAPI, state, metaData),
    ...getAuthorEditedBIEventPayload(state, updatedFields),
  };
  return blogBILogger?.authorEdited(payload);
};
