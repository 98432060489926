import initSchemaLogger, { Logger } from '@wix/bi-logger-blog-app-users';

import { FlowAPI } from '../types/controller';

export type BlogBILogger = Logger;

export const initBlogBILogger = (flowAPI: FlowAPI) => {
  const { biLoggerFactory } = flowAPI.controllerConfig.platformAPIs;
  const factory = biLoggerFactory?.();

  if (!factory) {
    return null;
  }

  return initSchemaLogger(factory)();
};
