import {
  Nullable,
  ImageChangeOptions,
  Thunk,
  ThunkWithArgs,
} from '../../types';
import {
  getToggleIsCoverRepositionModeAction,
  getToggleIsEditingProfileAction,
  getToggleIsCoverLoadingAction,
  getStopEditingProfileAction,
  getSetEditNameAction,
  getSetEditTitleAction,
  getSetEditPictureAction,
  getSetEditCoverAction,
} from '../actions/profile-page';
import { getSetViewedMemberAction } from '../actions';
import { openMobileProfileEdit } from '../../services/modal';
import { scheduleViewedMemberSync } from './common';

export const enterCoverRepositionMode = () =>
  getToggleIsCoverRepositionModeAction();

export const toggleIsEditingProfile: Thunk = () => async (
  dispatch,
  getState,
  extra,
) => {
  const { flowAPI, wixCodeApi, compId, membersService } = extra;

  if (flowAPI.environment.isMobile) {
    const onModalClose = async () => {
      const state = getState();
      const { viewed } = state.users;
      const updatedMember = await membersService.getMember(viewed.uid);
      scheduleViewedMemberSync(extra);
      dispatch(getSetViewedMemberAction(updatedMember));
    };

    await openMobileProfileEdit({ compId, wixCodeApi, onModalClose });
  } else {
    dispatch(getToggleIsEditingProfileAction());
  }
};

export const stopEditingProfile: Thunk = () => (dispatch, getState) => {
  const { users } = getState();
  const { viewed } = users;

  dispatch(getStopEditingProfileAction(viewed.name, viewed.title ?? null));
};

export const setEditName = (editName: string) => getSetEditNameAction(editName);

export const setEditTitle = (editTitle: string) =>
  getSetEditTitleAction(editTitle);

export const setEditPicture = (options: ImageChangeOptions) =>
  getSetEditPictureAction(options);

export const setEditCover: ThunkWithArgs<Nullable<ImageChangeOptions>> = (
  options,
) => (dispatch) => {
  dispatch(getToggleIsCoverLoadingAction());
  dispatch(getSetEditCoverAction(options));
  dispatch(getToggleIsCoverLoadingAction());
};
