import { BadgeType } from '@wix/members-badge-lib';

import { Handler, HTTPMethod } from '../types';
import request from './request';

interface BadgeListResponse {
  badges: BadgeType[];
}

class BadgesService {
  constructor(
    private readonly baseUrl: string,
    private readonly getInstance: Handler<string>,
  ) {}

  public readonly getBadgeList = async () => {
    const url = `${this.baseUrl}/badges`;
    const { badges } = await request<BadgeListResponse>(url, {
      method: HTTPMethod.Get,
      headers: this.getHeaders(),
    });

    return badges;
  };

  private readonly getHeaders = () => ({ Authorization: this.getInstance() });
}

export default BadgesService;
