import { MediaPlatformImage } from '@wix/members-domain-ts';

import {
  Thunk,
  ThunkDispatch,
  ThunkExtra,
  StoreState,
  PublicMember,
} from '../../types';
import { RootState } from '../root-reducer';
import { PartialUpdatableFields } from '../../services/members-service';
import { maybeUploadMediaToMediaStore } from '../../services/file-upload';
import {
  getStopEditingProfileAction,
  getToggleIsEditingProfileAction,
  getSetViewedMemberDetails,
} from '../actions';
import {
  emitProfileEditBIEvents,
  scheduleViewedMemberSync,
  toggleIsProfileSaving,
  toggleProfileSavedNotification,
} from './common';

const getUpdatedFields = async (
  { users, profilePage }: StoreState,
  services: ThunkExtra,
) => {
  const { editName, editTitle, editPicture, editCover } = profilePage;
  const { title } = users.viewed;

  const updateTitle = editTitle !== null && editTitle !== title;
  const updatedMedia = await maybeUploadMediaToMediaStore({
    defaultCover: users.viewed.cover as MediaPlatformImage,
    editCover,
    editPicture,
    services,
  });

  return {
    ...updatedMedia,
    ...(editName && editName !== users.viewed.name && { name: editName }),
    ...(updateTitle && { title: editTitle! }),
  } as PartialUpdatableFields;
};

const updateMember = async (
  { users }: RootState,
  updatedFields: PartialUpdatableFields,
  { membersService }: ThunkExtra,
) => {
  const { uid } = users.viewed;
  const updatedMember = await membersService.partialMemberUpdate(
    uid,
    updatedFields,
  );

  return updatedMember;
};

const setMemberDetails = (
  dispatch: ThunkDispatch,
  { name, title, cover, picture }: PublicMember,
) => {
  dispatch(getStopEditingProfileAction(name, title ?? null));
  dispatch(getSetViewedMemberDetails({ name, title, cover, picture }));
};

const resetEditModeProps = (dispatch: ThunkDispatch, { users }: StoreState) => {
  const { name, title } = users.viewed;
  dispatch(getStopEditingProfileAction(name, title ?? null));
};

export const saveProfile: Thunk = () => async (dispatch, getState, extra) => {
  toggleIsProfileSaving(dispatch, extra);
  dispatch(getToggleIsEditingProfileAction());

  const state = getState();
  const updatedFields = await getUpdatedFields(state, extra);
  const hasUpdatedFields = Object.keys(updatedFields).length;

  if (hasUpdatedFields) {
    const updatedMember = await updateMember(state, updatedFields, extra);

    setMemberDetails(dispatch, updatedMember);
    scheduleViewedMemberSync(extra);
    emitProfileEditBIEvents(state, updatedFields, extra);
    toggleProfileSavedNotification(extra);
  } else {
    resetEditModeProps(dispatch, state);
  }

  toggleIsProfileSaving(dispatch, extra);
};
