import {
  BIEvent,
  CommonBIEvent,
  AppLoadedBIEvent,
  AuthorEditedBIEvent,
  ProfileChangedBIEvent,
  FollowOrUnFollowBIEvent,
  RoleActionClickedBIEvent,
  MembersChatOpenedBIEvent,
  ProfileLayoutChangedBIEvent,
  ProfileDesignEditedBIEvent,
  ShowFFToggleBIEvent,
  AppSettingsLoadedBIEvent,
  Nullable,
} from '../types';
import { WixSDK } from '../components/ProfileCard/Settings/types';

type BIEventBase = CommonBIEvent & {
  evid: BIEvent;
  src: number;
};

interface Logger {
  log: <T extends BIEventBase>(event: T) => Promise<void>;
}

interface LoggerFactoryOptions {
  endpoint: string;
}

type LoggerFactory = (options: LoggerFactoryOptions) => Logger;

type EditorLoggerFactory = () => Logger;

type BILoggerFactory = () => { logger?: LoggerFactory };

type EditorBILoggerFactory = (
  Wix: WixSDK,
  options: LoggerFactoryOptions,
) => { logger: EditorLoggerFactory };

export type BIEventPayload<T extends BIEvent> = {
  [BIEvent.AuthorEdited]: AuthorEditedBIEvent;
  [BIEvent.ShowLeaveCommunityModal]: CommonBIEvent;
  [BIEvent.LeftCommunity]: CommonBIEvent;
  [BIEvent.ShowJoinCommunityModal]: CommonBIEvent;
  [BIEvent.JoinedCommunity]: CommonBIEvent;
  [BIEvent.FollowOrUnfollow]: FollowOrUnFollowBIEvent;
  [BIEvent.RoleActionClicked]: RoleActionClickedBIEvent;
  [BIEvent.AppLoaded]: AppLoadedBIEvent;
  [BIEvent.ProfileEdited]: ProfileChangedBIEvent;
  [BIEvent.MembersChatOpened]: MembersChatOpenedBIEvent;
  [BIEvent.ProfileLayoutChanged]: ProfileLayoutChangedBIEvent;
  [BIEvent.ProfileDesignEdited]: ProfileDesignEditedBIEvent;
  [BIEvent.ShowFFToggle]: ShowFFToggleBIEvent;
  [BIEvent.AppSettingsLoaded]: AppSettingsLoadedBIEvent;
}[T];

const uouEndpoint = 'members-app-uou';

const usersEndpoint = 'members-app-users';

export class BILogger {
  private readonly appSrc = 69;

  constructor(private readonly logger: Logger) {}

  public readonly log = async <T extends BIEvent>(
    biEvent: T,
    biEventPayload: BIEventPayload<T>,
  ) => {
    const event = { ...biEventPayload, evid: biEvent, src: this.appSrc };

    try {
      await this.logger.log(event);
    } catch (originalError) {
      const error = originalError as Error;
      console.log('BI logger error', event, error);
    }
  };
}

export const initBILogger = (biLoggerFactory?: BILoggerFactory) => {
  if (!biLoggerFactory) {
    return null;
  }

  const logger = biLoggerFactory().logger?.({ endpoint: uouEndpoint });
  return logger ? new BILogger(logger) : null;
};

export const initEditorBILogger = (biLoggerFactory: EditorBILoggerFactory) => {
  let biLogger: Nullable<BILogger> = null;

  return (Wix: WixSDK) => {
    if (biLogger !== null) {
      return biLogger;
    }

    const logger = biLoggerFactory(Wix, { endpoint: usersEndpoint }).logger();
    biLogger = new BILogger(logger);

    return biLogger;
  };
};
