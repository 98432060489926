import { RoleId } from '@wix/members-domain-ts';

import { BIEvent, Thunk, ThunkWithArgs } from '../../../types';
import { openModalWithCallback } from '../../../services/modal';
import { maybeNavigateToHomePage } from '../../../services/navigation';
import { emitBIEventWithPayload } from '../../../services/bi-event';
import { manageBadges } from './badges';
import { joinCommunity, leaveCommunity } from './community';
import { modalRoleAction } from './modal-roles';
import { toggleIsEditingProfile } from '../profile-page';

type CustomRolesActionsMap = { [key in RoleId]?: ThunkWithArgs<RoleId> };

const anonymousReport: Thunk = () => async (_, __, { wixCodeApi }) => {
  wixCodeApi.user.promptLogin({}).catch(() => {});
};

export const deleteMember: ThunkWithArgs<RoleId> = (roleId) => async (
  _,
  getState,
  { compId, platformAPIs, wixCodeApi, membersService },
) => {
  const { viewed } = getState().users;
  const payload = {};
  const onConfirm = async () => {
    await membersService.deleteMember(viewed.uid);
    await maybeNavigateToHomePage(wixCodeApi);
  };

  openModalWithCallback({
    compId,
    modalType: roleId,
    payload,
    platformAPIs,
    wixCodeApi,
    onConfirm,
  });
};

const customRolesActionsMap: CustomRolesActionsMap = {
  [RoleId.JOIN_COMMUNITY]: joinCommunity,
  [RoleId.LEAVE_COMMUNITY]: leaveCommunity,
  [RoleId.EDIT]: toggleIsEditingProfile,
  [RoleId.ANONYMOUS_REPORT]: anonymousReport,
  [RoleId.DELETE_MEMBER]: deleteMember,
  [RoleId.MANAGE_BADGES]: manageBadges,
};

export const executeRoleAction: ThunkWithArgs<RoleId> = (roleId) => async (
  dispatch,
  getState,
  extra,
) => {
  const state = getState();
  const { viewed } = state.users;

  emitBIEventWithPayload({
    state: getState(),
    extra,
    biEvent: BIEvent.RoleActionClicked,
    payload: { action_type: roleId, member_chosen: viewed.uid },
  });

  const customRoleAction = customRolesActionsMap[roleId];
  if (customRoleAction) {
    await customRoleAction(roleId)(dispatch, getState, extra);
    return;
  }

  await modalRoleAction(roleId)(dispatch, getState, extra);
};
