import { IWixStyleParams } from 'yoshi-flow-editor-runtime/tpa-settings';

import { BIEvent, Handler, MetaData, Nullable, PublicMember } from '../types';
import {
  ControllerConfig,
  RouterData,
  RouterPublicData,
  AppParams,
  FlowAPI,
  WixCodeApi,
} from '../types/controller';
import { Store } from '../store';
import {
  badgesApiAbsoluteBaseUrl,
  badgesApiRelativeBaseUrl,
  membersApiAbsoluteBaseUrl,
  membersApiRelativeBaseUrl,
} from '../constants/urls';
import { origin } from '../constants/bi';
import { SetSitePresetsPayload } from '../store/slices/global-settings-slice';
import {
  getSetBadgeListPayload,
  getSetInstalledAppsAction,
  getSetIsSocialChatAction,
  getSetRolesMapAction,
  getSetUsersAction,
  getSetGlobalSettingsAction,
  getSetComponentSettingsAction,
  getSetSitePresetsAction,
} from '../store/actions';
import BadgesService from './badges-service';
import MediaService from './media-service';
import MembersService from './members-service';
import SettingsService from './settings-service';
import { BILogger } from './bi-logger';
import { emitBIEventWithPayload } from './bi-event';

export interface Services {
  membersService: MembersService;
  badgesService: BadgesService;
  mediaService: MediaService;
  settingsService: SettingsService;
}

interface InitPropsOptions {
  currentMemberId: Nullable<string>;
  viewedMemberId: Nullable<string>;
  store: Store;
  flowAPI: FlowAPI;
  services: Services;
}

interface EmitAppLoadedBIEventOptions {
  widgetId: string;
  store: Store;
  metaData: Nullable<MetaData>;
  biLogger: Nullable<BILogger>;
  flowAPI: FlowAPI;
}

export const getMetaData = (getInstance: () => string) => {
  const instance = getInstance();
  if (!instance) {
    return null;
  }

  try {
    const [, base64Data] = instance.split('.', 2);
    const metaData = JSON.parse(atob(base64Data)) as MetaData;
    return metaData;
  } catch {
    return null;
  }
};

export const getMetaSiteId = (
  controllerConfig: ControllerConfig,
  metaData: Nullable<MetaData>,
) => {
  const { bi } = controllerConfig.platformAPIs;
  if (bi?.metaSiteId) {
    return bi.metaSiteId;
  }

  return metaData?.metaSiteId ?? null;
};

const getViewedMemberIdFromRouterData = (routerData?: RouterData) => {
  if (!routerData) {
    return null;
  }

  const { memberData, pageData, publicData } = routerData;
  const viewedMemberIdFromMemberData = memberData?.memberContactId;
  const viewedMemberIdFromPageData = pageData?.memberData?.memberContactId;
  const viewedMemberIdFromPublicData = publicData?.viewedUser?.id;
  const viewedMemberId =
    viewedMemberIdFromMemberData ??
    viewedMemberIdFromPageData ??
    viewedMemberIdFromPublicData;

  return viewedMemberId ?? null;
};

const getViewedMemberIdFromPublicRouterData = (wixCodeApi: WixCodeApi) => {
  const routerData = wixCodeApi.window.getRouterPublicData?.<RouterPublicData>();
  const viewedUser = routerData?.viewedUser;

  return viewedUser?.id ?? null;
};

export const getCurrentMemberId = (
  wixCodeApi: WixCodeApi,
  metaData: Nullable<MetaData>,
) => {
  const currentUser = wixCodeApi.user.currentUser;
  const currentUserId = currentUser.loggedIn ? currentUser.id : null;
  return metaData?.siteMemberId ?? currentUserId ?? null;
};

export const getViewedMemberId = (
  { appParams, wixCodeApi }: ControllerConfig,
  metaData: Nullable<MetaData>,
) => {
  const { routerReturnedData: routerData } = appParams as AppParams;
  const currentMemberId = getCurrentMemberId(wixCodeApi, metaData);
  const routerDataViewedMemberId = getViewedMemberIdFromRouterData(routerData);
  const publicRouterDataViewedMemberId = getViewedMemberIdFromPublicRouterData(
    wixCodeApi,
  );

  return (
    routerDataViewedMemberId ??
    publicRouterDataViewedMemberId ??
    currentMemberId ??
    null
  );
};

export const getInstanceFactory = ({
  appParams,
  wixCodeApi,
}: ControllerConfig) => {
  const userInstance = wixCodeApi.user.currentUser.instance;
  const appInstance = appParams.instance;
  let instance = userInstance || appInstance;

  wixCodeApi.site.onInstanceChanged((event) => {
    instance = event.instance;
  }, appParams.appDefinitionId);

  return () => instance;
};

export const initServices = (
  componentId: string,
  flowAPI: FlowAPI,
  getInstance: Handler<string>,
): Services => {
  const useAbsoluteUrl = flowAPI.environment.isSSR;
  const membersServiceBaseUrl = useAbsoluteUrl
    ? membersApiAbsoluteBaseUrl
    : membersApiRelativeBaseUrl;

  const badgesServiceBaseUrl = useAbsoluteUrl
    ? badgesApiAbsoluteBaseUrl
    : badgesApiRelativeBaseUrl;

  return {
    membersService: new MembersService(membersServiceBaseUrl, getInstance),
    badgesService: new BadgesService(badgesServiceBaseUrl, getInstance),
    mediaService: new MediaService(),
    settingsService: new SettingsService(
      componentId,
      membersServiceBaseUrl,
      getInstance,
    ),
  };
};

const getMembers = (
  currentMemberId: Nullable<string>,
  viewedMemberId: Nullable<string>,
  membersService: MembersService,
): [Promise<Nullable<PublicMember>>, Promise<Nullable<PublicMember>>] => {
  const currentMemberPromise = currentMemberId
    ? membersService.getMember(currentMemberId)
    : Promise.resolve(null);

  if (currentMemberId === viewedMemberId) {
    return [currentMemberPromise, currentMemberPromise];
  }

  const viewedMemberPromise = viewedMemberId
    ? membersService.getMember(viewedMemberId)
    : Promise.resolve(null);

  return [currentMemberPromise, viewedMemberPromise];
};

const getViewMode = ({ controllerConfig }: FlowAPI) => {
  return controllerConfig.wixCodeApi.window.viewMode;
};

export const setComponentSettings = (
  store: Store,
  settings: IWixStyleParams,
) => {
  const payload = { styleParams: settings as any };
  const action = getSetComponentSettingsAction(payload);

  store.dispatch(action);
};

export const setSitePresets = (
  store: Store,
  sitePresets: SetSitePresetsPayload,
) => store.dispatch(getSetSitePresetsAction(sitePresets));

export const initProps = async ({
  currentMemberId,
  viewedMemberId,
  store,
  flowAPI,
  services: { badgesService, membersService, settingsService },
}: InitPropsOptions) => {
  const [
    currentMember,
    viewedMember,
    installedApps,
    isSocialChat,
    rolesMap,
    badgeList,
    globalSettings,
  ] = await Promise.all([
    ...getMembers(currentMemberId, viewedMemberId, membersService),
    membersService.getInstalledApps(),
    membersService.getIsSocialChat(),
    membersService.getRolesMap(),
    badgesService.getBadgeList(),
    settingsService.getGlobalSettings(getViewMode(flowAPI)),
  ]);
  const users = {
    ...(viewedMember && { viewed: viewedMember }),
    current: currentMember,
  };

  store.dispatch(getSetUsersAction(users));
  store.dispatch(getSetBadgeListPayload(badgeList));
  store.dispatch(getSetRolesMapAction(rolesMap));
  store.dispatch(getSetIsSocialChatAction(isSocialChat));
  store.dispatch(getSetInstalledAppsAction(installedApps));
  store.dispatch(getSetGlobalSettingsAction(globalSettings));
};

export const emitAppLoadedBIEvent = ({
  widgetId,
  store,
  metaData,
  biLogger,
  flowAPI,
}: EmitAppLoadedBIEventOptions) => {
  emitBIEventWithPayload({
    state: store.getState(),
    extra: { metaData, biLogger, flowAPI },
    biEvent: BIEvent.AppLoaded,
    payload: {
      origin,
      page_name: origin,
      widget_name: origin,
      widget_id: widgetId,
    },
  });
};
