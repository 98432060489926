import { InjectedGlobalSettings, ThunkWithArgs } from '../../types';
import {
  getPatchGlobalSettingsAction,
  getSetViewedMemberFollowerCount,
  getSetViewedMemberFollowingCount,
} from '../actions';

export const updateViewedMemberFollowingCount: ThunkWithArgs<number> = (
  followingCountChange,
) => async (dispatch, getState) => {
  const { viewed } = getState().users;
  const setFollowingCountPayload = {
    followingCount: viewed.followingCount + followingCountChange,
  };

  dispatch(getSetViewedMemberFollowingCount(setFollowingCountPayload));
};

export const updateViewedMemberFollowerCount: ThunkWithArgs<number> = (
  followerCountChange,
) => async (dispatch, getState) => {
  const { viewed } = getState().users;
  const setFollowerCountPayload = {
    followerCount: viewed.followerCount + followerCountChange,
  };

  dispatch(getSetViewedMemberFollowerCount(setFollowerCountPayload));
};

export const patchGlobalSettingsInStore: ThunkWithArgs<
  Partial<InjectedGlobalSettings>
> = (changedSettings) => async (dispatch) => {
  dispatch(getPatchGlobalSettingsAction(changedSettings));
};
