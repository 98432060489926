import { HTTPMethod, HTTPStatus } from '../types';
import { HTTPError } from './error';

interface ShouldRetryFetchOptions {
  data?: RequestInit;
  response: Response;
  retryCount: number;
}

interface MaybeRetryFetchOptions extends ShouldRetryFetchOptions {
  url: string;
}

type FetchRetryWithTimeoutOptions = MaybeRetryFetchOptions;

const maxRetryCount = 2;

const defaultRetryDelay = 0;

const notFoundRetryDelay = 150;

// Calculation of the retry timeouts are based on discussion
// in the following Pull Request:
// https://github.com/wix-private/app-market/pull/26446
const getRetryDelay = (response: Response, retryCount: number) => {
  return response.status === HTTPStatus.NotFound
    ? retryCount * notFoundRetryDelay
    : defaultRetryDelay;
};

export const shouldRetryFetch = ({
  data,
  response,
  retryCount,
}: ShouldRetryFetchOptions) => {
  const isGetRequest = HTTPMethod.Get === data?.method;
  const isRetryCountExceeded = retryCount >= maxRetryCount;
  const isServerError = response.status >= 500;
  const isNotFoundError = response.status === HTTPStatus.NotFound;
  const isRetryableError = isServerError || isNotFoundError;

  return isGetRequest && isRetryableError && !isRetryCountExceeded;
};

const fetchRetryWithTimeout = ({
  url,
  data,
  response,
  retryCount,
}: FetchRetryWithTimeoutOptions) => {
  const delay = getRetryDelay(response, retryCount);
  const promise = new Promise<Response>((resolve, reject) => {
    setTimeout(async () => {
      try {
        const _response = await fetchWrapper(url, data, retryCount);
        resolve(_response);
      } catch (error) {
        reject(error);
      }
    }, delay);
  });
  return promise;
};

export const maybeRetryFetch = ({
  url,
  data,
  response,
  retryCount,
}: MaybeRetryFetchOptions): Promise<Response> => {
  if (shouldRetryFetch({ data, response, retryCount })) {
    return fetchRetryWithTimeout({
      url,
      data,
      response,
      retryCount: retryCount + 1,
    });
  }

  throw new HTTPError('HTTP request failed', response);
};

export const fetchWrapper = async (
  url: string,
  data?: RequestInit,
  retryCount = 0,
) => {
  const response = await fetch(url, data);
  if (!response.ok) {
    return maybeRetryFetch({ url, data, response, retryCount });
  }
  return response;
};

const request = async <T>(url: string, data?: RequestInit): Promise<T> => {
  const response = await fetchWrapper(url, data);
  return response.json();
};

export const requestText = async (url: string, data?: RequestInit) => {
  const response = await fetchWrapper(url, data);
  return response.text();
};

export default request;
