type ErrorDetails = { [key in string]: string };

export interface WithErrorDetails {
  errorDetails: ErrorDetails;
}

export class HTTPError extends Error implements WithErrorDetails {
  errorDetails: ErrorDetails;

  constructor(message: string, private readonly response: Response) {
    super(message);

    const { url, status, headers } = this.response;
    const requestId = headers.get('x-wix-request-id');

    this.name = 'HTTPError';
    this.errorDetails = {
      ...(requestId && { requestId }),
      requestUrl: url,
      responseStatus: status.toString(),
    };
  }
}
