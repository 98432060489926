import { MediaPlatformImage } from '@wix/members-domain-ts';

import { HTTPMethod } from '../types';
import request from './request';

interface UploadFileOptions {
  uploadUrl: string;
  uploadToken: string;
  fileName: string;
  fileDataUrl: string;
}

const mediaType = 'picture';

const membersAreaFolder = 'Members Area';

const visitorsUploadsFolderId = 'visitor-uploads';

class MediaService {
  public readonly uploadPicture = async ({
    uploadUrl,
    uploadToken,
    fileName,
    fileDataUrl,
  }: UploadFileOptions) => {
    const formData = new FormData();
    const blob = await this.dataUrlToBlob(fileDataUrl);

    formData.append('directory', membersAreaFolder);
    formData.append('parent_folder_id', visitorsUploadsFolderId);
    formData.append('upload_token', uploadToken);
    formData.append('media_type', mediaType);
    formData.append('file', blob, fileName);

    const [picture] = await request<MediaPlatformImage[]>(uploadUrl, {
      method: HTTPMethod.Post,
      body: formData,
    });
    const { width, height, file_name, original_file_name } = picture;

    return { width, height, file_name, original_file_name };
  };

  private readonly dataUrlToBlob = (url: string) =>
    fetch(url).then((res) => res.blob());
}

export default MediaService;
